<template>
  <div class="container">
    <div style="display:flex;">
      <BackButton :path="{ path: '/settings'}" />
      <h5 style="margin:auto;">{{familyName}}</h5>
    </div>
    <hr>
    <div class="row">
      <div class="">
        <ul class="list2">
          <FamilyMember class="user listitem flex clickable" v-for="(member,idx) in members" :key="member.ID" @click="toggle(idx, member.ID)" :class="{'active': idx === activeIndex}" :member="member">
          </FamilyMember>
        </ul>
      </div>
    </div>

    <div id="actionBtnsContainer" v-if="isAdmin==='1'">
      <div id="actionBtnsDiv">
        <div class="edit userAction actionDiv hiddenAction clickable" v-if="this.activeUserId" @click="goToFamilyUserEdit"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'pencil-alt']"/></div><div class="actionTxt">Edit</div></div>
        <div class="delete userAction actionDiv hiddenAction clickable" v-if="this.activeUserId" @click="showDelModal"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'trash']"/></div><div class="actionTxt">Delete</div></div>
        <div class="viewBas userAction actionDiv hiddenAction clickable" v-if="this.activeUserId"  @click="gotoSavingGoal"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'piggy-bank']" /></div><div class="actionTxt">Saving Goal</div></div>
        <div class="viewBas userAction actionDiv hiddenAction clickable" v-if="this.activeUserId"  @click="gotoUserBankAccounts"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'university']" /></div><div class="actionTxt">Bank Accounts</div></div>
        <div class="add userAction actionDiv clickable" @click="goToFamilyUserAdd"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'plus']"/></div><div class="actionTxt">Add Member</div></div>
      </div>
    </div>

  </div>


  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Are you sure you want to delete this family member?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes clickable" @click="delUser"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo clickable" @click="closeDelModal"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>

        <div v-if="noadminleftwarning" style="margin-top:20px;font-size:11px;color:red;">
          You can not delete yourself, because you are the only family admin left in your family. You can choose to delete your entire family in the Family Settings though.
        </div>
      </div>
    </vue-final-modal>
  </div>


  <div class="modalParent">
    <vue-final-modal v-model="showUserLimitModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">There is a limit of {{ this.$config.userLimit }} users.</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn clickable" @click="showUserLimitModal=false">Close</div>
        </div>
      </div>
    </vue-final-modal>
  </div>


</template>

<script>
// @ is an alias to /src
import FamilyMember from '@/components/FamilyMember'
import {VueFinalModal } from 'vue-final-modal';
import BackButton from "@/components/BackButton";

export default {
  name: 'Family',
  components: {
    BackButton,
    FamilyMember, VueFinalModal
  },
  data(){
    return{
      members:null,
      familyName:'',
      activeIndex: null,
      activeUserId: null,
      activeUser: {},
      showDeleteModal:false,
      showUserLimitModal:false,
      userInfo:{},
      isAdmin:'0',
      userCount:0,
      noadminleftwarning:false
    }
  },
  methods:{

    getFamilyMembers: async function(){
      await this.$root.doRefresh();
      let loader = this.$loading.show();
      var members = JSON.parse(localStorage.getItem('familyUsers'));
      var familyInfo = JSON.parse(localStorage.getItem('familyInfo'));
      this.members = members.filter(u => u.CENTRAL_BANK !== '1');
      this.userCount = this.members.length;
      //console.log(this.members);
      this.familyName = familyInfo.NAME;
      //console.log(response);
      loader.hide();
    },

    toggle: function(index, userId){
      this.$root.playClickSound();
      this.activeIndex = index;
      this.activeUserId = userId;
      this.activeUser = this.members.filter(u => u.ID === userId)[0]

    },
    showDelModal:function(){
      this.$root.playClickSound();
      this.showDeleteModal=true;
    },
    goToFamilyUserAdd:function(){
      //console.log(this.$config.userLimit);
      if(this.userCount === this.$config.userLimit){
        this.showUserLimitModal=true;
      }else{
        this.$root.gotoRoute({ path: '/settings/family/user'})
      }


    },
    goToFamilyUserEdit:function(){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/settings/family/user', query: { userid: this.activeUserId }})
    },

    delUser: async function(){
      this.$root.playClickSound();
      let response = await this.axios
          .post(this.$hostApi,{task:'delUser', userId:this.activeUserId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      if(response.data.res === 'noadminleft'){
        this.noadminleftwarning = true;
      }else{
        await this.$root.doRefresh();
        if(this.activeUserId === this.userInfo.ID){
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }else{
            this.activeIndex = null;
            this.activeUserId = null;
            this.showDeleteModal = false;
            this.getFamilyMembers();
          }
        }
    },

    closeDelModal(){
      this.$root.playClickSound();
      this.showDeleteModal=false;
      this.noadminleftwarning = false;
    },

    gotoUserBankAccounts(){
      this.$root.gotoRoute({ path: '/settings/bas', query: { userid: this.activeUserId }})
    },
    gotoSavingGoal(){
      this.$root.gotoRoute({ path: '/settings/family/savinggoal', query: { userid: this.activeUserId }})
    }
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.isAdmin = userInfo && userInfo.FAMILY_ADMIN ? userInfo.FAMILY_ADMIN : '0';

  },
  mounted() {
    document.title = "Manage Family Members";
    this.getFamilyMembers();
  }
}
</script>

<style scoped>




.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

#actionBtnsContainer {
  position: fixed;
  bottom: 100px;
  right: 50px;
  height: 45px;
  color: #fff;
}

#actionBtnsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionDiv {
  margin-left: 10px;

}

.actionBtn {
  width: 45px;
  height: 45px;
  background-color: #0dcaf0;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.actionTxt {
  color: #3b3b3b;
  font-size: 8px;
  height: 10px;
  text-align: center;
}





/*modal*/
.modal-dialog.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.delConfirmBtn svg {
  margin-right: 10px;
}


.modalParent :deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}





</style>

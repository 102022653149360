<template>
  <li class="user listitem flex">
    <div class="d-flex flex">
      <div class='avatar'><img :src="member.AVATAR_URL" alt="avatar"></div>
      <div>
        <div>{{member.FULL_NAME}}</div>
        <div class="email">{{member.EMAIL}}</div>
<!--        <div class="expire" :class="{expired:member.EXPIRE_STATUS === '4'}">account {{getExpireTxt(member.EXPIRE_STATUS)}} on {{member.EXPIRE_DT}}</div>-->
      </div>
      <div class='selectDiv'><font-awesome-icon :icon="['fas', 'circle']" class="selectSpot"/></div>
    </div>
  </li>

</template>

<script>
export default {
  name: 'FamilyMember',
  props: {
    member: {},
  },
  data(){
    return{
      expiretxt:'expires'
    }
  },
  methods:{
    getImgUrl(pic) {
      return require('../assets/'+pic)
    },
    goToTransactions:function(account){
      this.$root.gotoRoute({ path: '/accounts/transactions', query: { baId: account.BA_ID } })
    },
    getExpireTxt:function(expireStatus){
      return expireStatus === '4' ? 'expired' : 'expires';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

.balance{
  font-size:20px;
}

.accountName{
  vertical-align: center;
}

.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

.avatar {
  width: 60px;
  margin: 5px 10px;
}

.selectDiv{
  position:absolute;
  right:10px;
  width:50px;
}

.list2 li{

  margin:3px 5px;

  align-items: center;
  border:2px solid #fff;
}

.list2 .avatar img{
  width:40px;
  border:2px solid #9c9c9c;
  border-radius: 50%;
  padding:3px;
}

.list2 li.active{
  border:2px solid #0e90d2;
}

.list2 li{
  position:relative;
  background-color: #f5f5f5;
}

.email{
  font-size:12px;
}




.selectDiv{
  position:absolute;
  right:10px;
  width:50px;
}

.selectSpot{
  color:#fff;
}

li.active .selectSpot{
  color: #0e90d2;
}

.expire{
  font-size:11px;
  color:#0e90d2;
}

.expire.expired{
  color:red;
}

</style>
